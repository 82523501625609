import { ReactNode, useContext } from 'react'

import { ThemeContext } from 'styled-components'
import { CheckoutHeader, CheckoutWrapper } from './CheckoutContainer.styled'

type CheckoutContainerProps = {
  className?: string
  children?: ReactNode
}

export const CheckoutContainer = ({ className, children }: CheckoutContainerProps) => {
  const themeContext = useContext(ThemeContext)

  return (
    <CheckoutWrapper className={className}>
      <CheckoutHeader>
        <span>
          <img src={themeContext.brandLogo} alt='Logo' />
        </span>
      </CheckoutHeader>

      {children}
    </CheckoutWrapper>
  )
}
