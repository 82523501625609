import cn from 'classnames'
import { tr } from 'mmfintech-commons'

import { MethodLogo } from '../MethodLogo'
import { MethodButtonChecked, MethodButtonStyled } from './MethodButton.styled'

import checkedIcon from '../../../assets/icons/checked.svg'

type MethodButtonProps = {
  name: string
  selected: boolean
  setSelected: (name: string) => void
}

export const MethodButton = ({ name, selected, setSelected }: MethodButtonProps) => (
  <MethodButtonStyled className={cn({ selected })} onClick={() => setSelected(name)} data-test='button-payment-method'>
    <MethodLogo className={cn('image-' + name.toLowerCase(), { selected })}>
      {selected && (
        <MethodButtonChecked>
          <img src={checkedIcon} alt='' />
        </MethodButtonChecked>
      )}
    </MethodLogo>
    {tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
  </MethodButtonStyled>
)
