import styled from 'styled-components'

export const MerchantPaneWrapper = styled.div`
  background: #ffffff;
  border: 1px solid lightgray;
  border-radius: 1rem;
  color: #000000;
  width: 100%;
  margin-bottom: 2rem;

  .left {
    background: rgba(163, 184, 194, 0.35);
    padding: 2.2rem 2rem;
    font-size: 2rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2.4rem;

    .value {
      font-size: 3.2rem;
      font-weight: 600;
      line-height: 3.9rem;
      margin-top: 0;
    }
  }

  .right {
    background: #ffffff;
    padding: 2rem 2rem 4rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    font-size: 1.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.9rem;

    .row {
      margin-top: 2rem;
      :first-of-type {
        margin-top: 0;
      }
    }

    .label {
      color: #85a0ad;
      font-size: 1rem;
      line-height: 1.2rem;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }
  }

  @media (min-width: 500px) {
    .left {
      padding: 2.2rem 5rem;
    }
    .right {
      padding: 2rem 5rem 4rem;
    }
  }
`

export const MerchantPaneAmountContainer = styled.div`
  background: rgba(163, 184, 194, 0.35);
  border-radius: 1rem 1rem 0 0;
  padding: 2.2rem 2rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 2.4rem;

  .value {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 3.9rem;
    margin-top: 0;
  }

  @media (min-width: 500px) {
    padding: 2.2rem 5rem;
  }
`

export const MerchantPaneInfoContainer = styled.div`
  background: #ffffff;
  border-radius: 0 0 1rem 1rem;
  padding: 2rem 2rem 4rem;

  @media (min-width: 500px) {
    padding: 2rem 5rem 4rem;
  }

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;

  .row {
    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }
  }

  .label {
    color: #85a0ad;
    font-size: 1rem;
    line-height: 1.2rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }

  .subtitle {
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;
  }
`

export const MerchantPaneLogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-height: 8rem;
    max-width: 20rem;
    margin-right: -2rem;
  }

  .fee {
    color: #678899;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 15px;
  }
`
