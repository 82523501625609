import { ReactNode } from 'react'
import { toast } from 'react-hot-toast'
import cn from 'classnames'

import { Button } from 'mmfintech-portal-commons'
import { LabelValueWrapper } from './LabelValue.styled'

import { copyTextToClipboard, tr } from 'mmfintech-commons'

type LabelValueProps = {
  label?: ReactNode
  value?: string
  dataTest?: string
  enableCopy?: boolean
  copyValue?: string
  valueClass?: string
}

export const LabelValue = ({ label, value, dataTest, enableCopy, copyValue, valueClass }: LabelValueProps) => {
  const handleCopyClick = () => {
    copyTextToClipboard(copyValue || value)
    const message = tr('FRONTEND.BANKING.COMMON.COPY_SUCCESS', 'Value copied to clipboard.')
    toast.remove()
    toast.success(message.toString())
  }

  if (value?.length) {
    return (
      <LabelValueWrapper>
        <div className='label'>{label}</div>
        {enableCopy ? (
          <div className='copy-box'>
            <span data-test={dataTest}>{value}</span>
            <Button
              type='button'
              color='primary'
              text={tr('FRONTEND.BUTTONS.COPY', 'Copy')}
              onClick={handleCopyClick}
            />
          </div>
        ) : (
          <div className={cn('value', valueClass)} data-test={dataTest}>
            {value}
          </div>
        )}
      </LabelValueWrapper>
    )
  }

  return null
}
