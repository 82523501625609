import cn from 'classnames'
import { tr } from 'mmfintech-commons'

import { MethodLogo } from '../MethodLogo'
import { MethodPaneWrapper } from './MethodPane.styled'

type MethodPaneProps = {
  method: string
}

export const MethodPane = ({ method }: MethodPaneProps) =>
  method ? (
    <MethodPaneWrapper>
      <span>{tr('METADATA.PAYMENT_OPTIONS.' + method, method)}</span>
      <MethodLogo className={cn('icon', 'image-' + method.toLowerCase())} />
    </MethodPaneWrapper>
  ) : null
