import styled from 'styled-components'

export const MethodPaneWrapper = styled.div`
  background: rgba(49, 131, 255, 0.05);
  border: 1px solid rgba(0, 97, 232, 0.3);
  border-radius: 8px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.3rem 2rem;

  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.9rem;

  .icon {
    width: 3.5rem;
    height: 3.5rem;
    background-size: contain;
    border: none;
  }
`
