import { Button } from 'mmfintech-portal-commons'
import { ErrorIcon } from './ErrorIcon'
import { Alert, AlertWrapper } from './ErrorFallback.styled'

export const ErrorFallback = ({ resetErrorBoundary }) => (
  <AlertWrapper>
    <Alert>
      <ErrorIcon />
      <p>An unexpected error occurred</p>
      {resetErrorBoundary ? (
        <Button color='alternative' onClick={resetErrorBoundary} text='Go back to the home page' />
      ) : null}
    </Alert>
  </AlertWrapper>
)
