// noinspection ES6CheckImport
import dompurify from 'dompurify'
import parse from 'html-react-parser'

import { SuccessIcon } from '../../../icons'
import { PreviewMessageStyled } from './PreviewMessage.styled'

import { tr } from 'mmfintech-commons'

export const PreviewMessage = ({ result }) => {
  const message = result.replace(/\r?\n/g, '<br/>').replace(/\\n/g, '<br/>')

  return (
    <PreviewMessageStyled>
      <SuccessIcon />
      <div>
        <div className='title'>{tr('FRONTEND.DEPOSIT.RESULT.SUCCESS', 'Success')}</div>
        {parse(dompurify.sanitize(message))}
      </div>
    </PreviewMessageStyled>
  )
}
