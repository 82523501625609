import { useEffect, Suspense } from 'react'

import { useDispatch } from 'react-redux'
import { Route, Switch, Redirect, BrowserRouter as Router, RouteProps } from 'react-router-dom'

import { withTranslation } from 'react-i18next'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'

import { ContentWrapper, ErrorFallback, SuspenseFallback } from './components'

import theme from './theme'
import routes from './routes'
import settings from './settings'
import { actions, configuration } from 'mmfintech-checkout-commons'
import { ThemeProvider } from 'styled-components'

import { ThunkDispatch } from 'redux-thunk'

const AppInner = () => {
  useEffect(() => {
    configuration.createBackendConfig(settings.backendForLocalhost)
    // configuration.createCardGatewayConfig(settings.)
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ContentWrapper>
          <Switch>
            {routes.map((route, index) => {
              const { path, exact, redirect, component } = route
              if (redirect) {
                return (
                  <Route key={index} exact={exact} path={path}>
                    <Redirect to={redirect} />
                  </Route>
                )
              }

              if (component) {
                return <CustomRoute key={index} path={path} exact={exact} component={component} />
              }

              return null
            })}
          </Switch>
        </ContentWrapper>

        <Toaster
          position='top-right'
          containerStyle={{
            top: '10rem'
          }}
          toastOptions={{
            className: '',
            style: {
              color: '#000000',
              fontFamily: 'inherit',
              fontSize: '1.4rem',
              fontStyle: 'normal',
              padding: '1.5rem',
              borderRadius: '0'
            },
            success: {}
          }}
        />
      </Router>
    </ThemeProvider>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  return (
    <Suspense fallback={<SuspenseFallback />}>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error: Error, info: { componentStack: string }) =>
          void dispatch(actions.common.errorLogging(error.toString(), info))
        }
        onReset={() => window.location.replace('/')}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const CustomRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route {...rest} render={props => <Component {...props} />} />
)
