import { useState } from 'react'

import axios from 'axios'

import { tr, useFormValues } from 'mmfintech-commons'

export const useFakeBankLogin = () => {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)

  const fakeValues = useFormValues({
    username: { required: true },
    password: { required: true }
  })

  const loginResponse = () => {
    setError(tr('CHECKOUT.BANK_LOGIN_REQUEST.ERROR', 'Bank is currently unavailable'))
  }

  const login = async (url: string) => {
    setLoading(true)

    if (url) {
      try {
        await axios({
          url,
          method: 'POST',
          data: fakeValues.prepare()
        })
      } catch (error) {
        //nothing
      }
    }

    setTimeout(() => {
      setLoading(false)
      loginResponse()
    }, 1500)
  }

  const reset = () => {
    fakeValues.reset({
      username: { required: true },
      password: { required: true }
    })
    setError(null)
  }

  return {
    error,
    login,
    loading,
    reset,
    values: fakeValues
  }
}
