import { shallowEqual, useSelector } from 'react-redux'

import QRCode from 'qrcode.react'

import { Button } from 'mmfintech-portal-commons'
import { LabelValue, WarningMessage } from '../../../components'
import { CryptoPreviewTitle, CryptoPreviewWrapper } from './PreviewCrypto.styled'

import { findCurrencyPrecision, formatFloat, formatMoney, tr } from 'mmfintech-commons'

type PreviewCryptoProps = {
  data: any
  onClose?: () => void
  onBack?: () => void
}

export const PreviewCrypto = ({ data, onClose, onBack }: PreviewCryptoProps) => {
  const { currencies } = useSelector(({ common: { currencies } }) => ({ currencies }), shallowEqual)

  const { address, currency, tag, processingAmount } = data || {}

  return (
    <>
      <CryptoPreviewWrapper>
        <CryptoPreviewTitle>{tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TITLE', 'Details')}</CryptoPreviewTitle>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.ADDRESS', 'Deposit address')}
          value={address}
          enableCopy={true}
          dataTest='address'
        />

        <p className='text-center code bordered'>
          <QRCode value={address} renderAs='svg' size={150} />
        </p>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TAG', 'Tag')}
          value={tag}
          dataTest='tag'
          enableCopy={true}
        />

        <LabelValue
          label={tr('FRONTEND.BANKING.COMMON.AMOUNT_LABEL', 'Amount')}
          value={formatMoney(processingAmount, currency, currencies)}
          dataTest='amount'
          enableCopy={true}
          copyValue={formatFloat(processingAmount, findCurrencyPrecision(currency, currencies))}
        />

        <div className='mb-2' />

        {typeof onClose === 'function' && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.CLOSE', 'Close')}
            onClick={onClose}
            data-test='button-close'
          />
        )}
        {typeof onBack === 'function' && (
          <Button
            type='button'
            color='primary'
            text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
            onClick={onBack}
            data-test='button-back'
          />
        )}
      </CryptoPreviewWrapper>

      <WarningMessage currency={currency} />
    </>
  )
}
