import styled from 'styled-components'

export const MethodButtonStyled = styled.div`
  cursor: pointer;
  position: relative;

  width: 100%;
  max-width: 30%;
  padding: 0;

  .use-grid & {
    max-width: unset;
  }

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  color: #000000;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7rem;
  text-align: center;

  &.selected {
    color: #000000;
  }

  :hover {
    color: #000000;
  }
`

export const MethodButtonChecked = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(7px, -7px);
`
